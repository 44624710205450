<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container class="page">
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, onMounted } from "vue";
import Layout from "@/views/Layout.vue";

import ThemeApp from "@/utils/styles/themes/Theme";

export default {
  name: "LayoutDefault",
  components: {
    Layout,
  },

  setup() {
    const themeApp = new ThemeApp();

    onMounted(() => {
      themeApp.setThemeById(3);
    });

    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style lang="scss">
.page {
  height: 100vh;
}
</style>
