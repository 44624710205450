import defaultTheme from "@/utils/styles/themes/defaultTheme";
import exampleTheme from "@/utils/styles/themes/exampleTheme";
import dasaTheme from "@/utils/styles/themes/dasaTheme";
import liveloTheme from "@/utils/styles/themes/liveloTheme";

export default class ThemeApp {
  setThemeById(id) {
    switch (id) {
      case 1:
        defaultTheme();
        return;
      case 2:
        dasaTheme();
        return;
      case 3:
        liveloTheme();
        return;
      default:
        defaultTheme();
        return;
    }
  }
}
