<template>
  <div class="side-bar">
    <div class="header">
      <q-img src="@/assets/logo/livelo.svg" style="max-width: 123px" />
    </div>
    <div class="actions items-center">
      <CardPerson
        :image="userData.avatar_location"
        :userData="userData"
        :company="userData.company_name"
        :progress="84"
        :level="18"
        :name="nameUser"
        :typeUser="userData.role"
      />
      <div class="menu-list justify-center q-mt-xl">
        <ListMenu :menuOptions="menuOptions" :chatData="chat">
          <template v-slot:moreOptions>
            <DropListMenu
              v-if="
                validateActionsPage('menu-admin') ||
                validateActionsPage('users-view') ||
                validateActionsPage('groups-view') ||
                userData.master == 1 ||
                validateActionsPage('company-humor-view')
              "
              :iconName="Company"
              name="Administração"
              :adminMenu="adminMenu"
            />
            <DropListMenu
              v-if="
                validateActionsPage('menu-admin') ||
                validateActionsPage('users-view') ||
                validateActionsPage('groups-view') ||
                userData.master == 1 ||
                validateActionsPage('analytic')
              "
              :iconName="Data"
              name="Analítico"
              :adminMenu="dataMenu"
            />
            <!-- <div
              class="item"
              v-if="
                validateActionsPage('menu-admin') ||
                validateActionsPage('users-view') ||
                validateActionsPage('groups-view') ||
                userData.master == 1
              "
            >
              <div class="flex full-width">
                <q-item
                  clickable
                  dense
                  exact
                  v-ripple
                  :class="[
                    'flex items-center',
                    showAdm ? 'bg-white text-black' : 'text-white',
                  ]"
                  active-class="bg-white text-black"
                  @click="toogleAdm()"
                >
                  <div class="avatar-menu">
                    <q-img
                      v-if="!showAdm"
                      width="100%"
                      :src="
                        require(`@/assets/icons/menu/white_icon/company.svg`)
                      "
                    />
                    <q-img
                      v-else
                      width="100%"
                      :src="require(`@/assets/icons/menu/dark_icon/company.svg`)"
                    />
                  </div>
                  <q-item-section>Administração </q-item-section>
                </q-item>
                <div class="q-mt-sm" v-if="showAdm">
                  <div v-for="adminItem in adminMenu" :key="adminItem.route">
                    <q-item
                      v-if="adminItem.permission"
                      clickable
                      dense
                      :to="{ name: adminItem.routeName }"
                      exact
                      v-ripple
                      :class="[
                        'q-ml-lg  flex items-center',
                        route.name != adminItem.routeName
                          ? 'text-white'
                          : 'bg-white text-black',
                      ]"
                      class="text-white flex items-center"
                      active-class=""
                    >
                      <div class="avatar-menu">
                        <q-img
                          v-if="route.name != adminItem.routeName"
                          width="80%"
                          :src="
                            require(`@/assets/icons/menu/white_icon/${adminItem.icon}.svg`)
                          "
                        />
                        <q-img
                          v-else
                          width="80%"
                          :src="
                            require(`@/assets/icons/menu/dark_icon/${adminItem.icon}.svg`)
                          "
                        />
                      </div>
                      <q-item-section>{{adminItem.name}}</q-item-section>
                    </q-item>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="item">
              <q-item
                clickable
                dense
                @click="logout"
                exact
                v-ripple
                class="text-white flex items-center"
                active-class="bg-white text-black"
              >
                <div class="avatar-menu q-pl-xs">
                  <q-img width="100%" src="@/assets/icons/logout.svg" />
                </div>
                <q-item-section>Sair</q-item-section>
              </q-item>
            </div>
          </template>
        </ListMenu>
      </div>
    </div>
  </div>
</template>

<script>
//----ICONS
import Company from "@/assets/icons/menu/Company.vue";
import Data from "@/assets/icons/menu/Data.vue";
import Groups from "@/assets/icons/menu/Groups.vue";
import Users from "@/assets/icons/menu/Users.vue";
import Dashboard from "@/assets/icons/menu/Dashboard.vue";
import Reports from "@/assets/icons/menu/Reports.vue";
import History from "@/assets/icons/menu/History.vue";

//----COMPONENTS
import CardPerson from "@/components/sidebar/cards/CardPerson.vue";
import StoreBtn from "@/components/sidebar/buttons/StoreBtn.vue";
import ListMenu from "@/components/sidebar/listItems/ListMenu.vue";
import DropListMenu from "@/components/sidebar/listItems/DropListMenu.vue";

//----SERVICES
import ChatServices from "@/services/ChatServices";
import { HttpTransportType } from "@aspnet/signalr";
import UserServices from "@/services/UserServices";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";
import Socket from "@/utils/Socket";

//----VUEJS
import { ref, onMounted, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "SideBar",
  components: {
    CardPerson,
    StoreBtn,
    ListMenu,
    DropListMenu,
  },

  setup() {
    //----VUEJS
    const route = useRoute();
    const store = useStore();

    //----SERVICES
    const _socket = new Socket();
    const _chatServices = new ChatServices();
    const canUserAccessClass = new CanUserAccessClass();
    const userServices = new UserServices();

    //----CONSTANTS
    const refAudioChat = ref(null);
    const userData = ref(store.state.userdata);
    const adminMenu = ref([
      isUserMaster()
        ? {
            name: "Empresa",
            icon: Company,
            route: "/company",
            routeName: "company",
            permission: isUserMaster(),
          }
        : {
            name: "Empresa",
            icon: Company,
            route: "/company-configuration",
            routeName: "company-configuration",
            permission:
              validateActionsPage("users-view") ||
              validateActionsPage("company-humor-view"),
          },
      {
        name: "Grupos",
        icon: Groups,
        route: "/groups",
        routeName: "groups",
        permission: validateActionsPage("groups-view"),
      },
      {
        name: "Usuários",
        icon: Users,
        route: "/users",
        routeName: "users",
        permission:
          validateActionsPage("users-view") || validateActionsPage("users"),
      },
      //{name: 'Histórico', icon: 'report', route: '/history', routeName: 'history', permission: true},
    ]);
    const dataMenu = ref([
      {
        name: "Relatórios",
        icon: Reports,
        route: "/reports",
        routeName: "reports",
        permission: validateActionsPage("analytic-reports"),
      },
      {
        name: "Histórico",
        icon: History,
        route: "/history",
        routeName: "history",
        permission: validateActionsPage("analytic-history"),
      },
      {
        name: "Dashboard",
        icon: Dashboard,
        route: "/dashboard",
        routeName: "dashboard",
        permission: validateActionsPage("users-view"),
        parent_id: 0,
      },
    ]);

    //----VARIABLES
    let menuOptions = ref([]);
    let showAdm = ref(false);
    let chat = reactive({
      isNewMessage: false,
      quantity: 0,
    });

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function isUserMaster() {
      let MASTER_ID = 1;
      return userData.value.master == MASTER_ID;
    }

    function _getMenuOptions() {
      menuOptions.value = JSON.parse(localStorage.getItem("vuex")).menu;
    }

    async function logout() {
      await userServices.logout().then(() => {
        localStorage.clear();
        store.commit("setUserData", null);
      });

      window.location = "/";
    }

    function toogleAdm() {
      showAdm.value = !showAdm.value;
    }

    function _listingNewMessages() {
      store.state.socket.connection.on(
        "ReceiveMessage",
        (conversationId, message) => {
          if (message.user_id != userData.value.id) {
            if (route.name != "chat") {
              let lastMessage = message.name + ": " + message.message;
              chat.isNewMessage = true;
              chat.quantity = chat.quantity + 1;
              _changeTitleOfPage(lastMessage);

              store.dispatch("AddMessages", {
                conversationId: conversationId,
                message: message,
              });
              //_somChat();
            }
          }
        }
      );
    }

    // function _somChat() {
    //   document.getElementById("chatAudio").play();
    // }

    function _socketConnect() {
      store.state.socket.connection
        .start({
          transport: [
            HttpTransportType.WebSockets,
            HttpTransportType.LongPolling,
            HttpTransportType.ServerSentEvents,
          ],
        })
        .then(() => {
          console.log("Connected!");
          _loadChats();
          _listingNewMessages();
        })
        .catch((e) => console.log("Connection failed: ", e));
    }

    function _changeTitleOfPage(title) {
      document.title = "Lorni " + title;
    }

    async function _loadChats() {
      await _chatServices.getAllChats();
    }

    function accessChat() {
      chat.isNewMessage = false;
      chat.quantity = 0;
    }

    onMounted(() => {
      _getMenuOptions();
      store.commit("setSocket", _socket);

      _socketConnect();
    });

    const nameUser = computed(() => {
      const { first_name, last_name, nick_name } = store.state.userdata;

      if (nick_name?.length === 0 || nick_name == "" || nick_name == null) {
        return `${first_name} ${last_name}`;
      } else {
        return `${first_name} ${last_name} (${nick_name})`;
      }
    });

    return {
      //----ICONS
      Company,
      Data,

      //----CONSTANTS
      route,
      userData,
      adminMenu,
      dataMenu,
      refAudioChat,
      nameUser,

      //----VARIABLES
      menuOptions,
      showAdm,
      chat,

      //----FUNCTIONS
      toogleAdm,
      validateActionsPage,
      isUserMaster,
      logout,
      accessChat,
    };
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  background: var(--q-primary);
  height: 100%;
  /* width: 100%;   */
  /* min-height: 19vh; */
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //justify-content: space-between;

  padding: 0 1%;

  > label {
    margin: 5% 0 3% 0;
  }

  .actions {
    display: flex;
    overflow-y: auto;
    width: 100%;
    flex-direction: row;

    > button {
      display: flex;
    }
  }

  @media (min-width: 1040px) {
    /* height: 100%; */

    .text-profile {
      display: none;
    }

    flex-direction: column;
    align-items: center;
    padding: 0 3% 3% 3%;

    .header {
      width: 100%;
    }

    .menu-list {
      display: flex;
      > div {
        width: 70%;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;

      > button {
        display: none;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #ffffff23;
  padding: 0.8rem 0;
  border-radius: 0px 0px 40px 40px;
  margin-bottom: 15%;
}

.menu-list {
  width: 100%;
  /* height: 28%; */
  overflow: auto;
  display: none;
}

.dropdown-list {
  width: 100%;
  overflow: auto;
  display: flex;
  // background: #1a1a1a;
}

.menu-list,
.dropdown-list {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff23;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--q-primary);
  }

  .item {
    display: flex;
    align-items: center;
    margin: 4px 0;

    .q-item {
      width: 100%;
      border-radius: 20px;
    }

    .avatar-menu {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: auto;

      margin-right: 16px;
    }
  }
}
</style>
