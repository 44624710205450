<template>
  <div class="content-login col-12 row">
    <div
      class="content-painel-login flex items-center justify-center col-md-4 col-sm-12 col-xs-12"
    >
      <div class="painel-login column flex items-center justify-center">
        <div class="painel-form-login">
          <div class="brand-logo flex q-py-lg row col-12">
            <q-img
              class="q-pl-md"
              src="../assets/logo/logo.svg"
              spinner-color="white"
              style="height: 47px; width: 150px"
            />
      <!--       <q-img
              class="q-pl-md"
              src="../assets/logo/dasa.svg"
              spinner-color="white"
              style="height: 47px; width: 150px"
            /> -->
          </div>
          <div class="header-form">
            <div class="title-header">
              <label>Plataforma digital de aprendizado corporativo</label>
            </div>
            <div class="subtitle-header flex column">
              <label>Bem vindo(a) de volta!</label>
              <label>Utilize suas credenciais para acessar o Lorni.</label>
            </div>
          </div>
          <div class="form">
            <form @submit.prevent.stop="onSubmit">
              <div class="col-12 q-mt-md">
                <q-input
                  class="input-login"
                  :placeholder="'Informe sua matricula ou email'"
                  outlined
                  type="text"
                  v-model="auth.username"
                  :dense="true"
                  bg-color="grey-3"
                  color="default-pink"
                  autocomplete="username"
                  :rules="[
                    (val) =>
                      !!val ||
                      (auth.loginInternal
                        ? 'O campo matricula é obrigatório.'
                        : 'O campo email é obrigatório.'),
                    isValidEmail,
                  ]"
                >
                  <template v-slot:prepend v-if="auth.loginInternal">
                    <q-icon name="person" style="color: #ccc" />
                  </template>
                  <template v-slot:prepend v-else>
                    <q-icon name="o_mail" style="color: #ccc" />
                  </template>
                </q-input>
              </div>
              <div class="col-12 q-mt-sm">
                <q-input
                  class="input-login"
                  :type="isPwd ? 'password' : 'text'"
                  placeholder="Senha"
                  outlined
                  v-model="auth.password"
                  :dense="true"
                  bg-color="grey-3"
                  color="default-pink"
                  autocomplete="current-password"
                  :rules="[(val) => !!val || 'O campo senha é obrigatório.']"
                >
                  <template v-slot:prepend>
                    <q-icon name="o_vpn_key" style="color: #ccc" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
              </div>
              <div class="action-form col-12 row">
                <div class="col-md-12 flex justify-end section-btn">
                  <q-btn
                    unelevated
                    no-caps
                    color="default-pink"
                    text-color="default-white"
                    :loading="btnLoading"
                    size="md"
                    type="submit"
                    label="Entrar"
                    class="btn-login"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="content-label-register q-mt-md">
            <div class="label-forget-my-password flex justify-center">
              <!--              <a href="#">Esqueceu a senha?</a>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container-carousel col-md-8 col-sm-12 col-xs-12 flex column justify-around items-center"
    >
      <div class="title-banner">
        <label
          >O Lorni te ajuda <br />
          a crescer.</label
        >
      </div>
      <div class="q-px-xl panel-carousel">
        <q-carousel
          animated
          v-model="slide"
          navigation
          navigation-active-icon="radio_button_unchecked"
          infinite
          height="50vh"
          :autoplay="true"
          transition-prev="slide-right"
          transition-next="slide-left"
        >
          <q-carousel-slide
            :name="1"
            img-src="../assets/images/banner-login.svg"
          />
          <q-carousel-slide
            :name="2"
            img-src="../assets/images/banner-login.svg"
          />
          <q-carousel-slide
            :name="3"
            img-src="../assets/images/banner-login.svg"
          />
          <q-carousel-slide
            :name="4"
            img-src="../assets/images/banner-login.svg"
          />
        </q-carousel>
      </div>
    </div>
  </div>
</template>

<script>
//----SERVICES
import AuthDataServices from "../services/AuthDataServices";
import CompanyServices from "../services/CompanyServices";

//-----VUEJS
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

//-----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "Login",
  setup(props) {
    //----SERVICES
    const authDataServices = new AuthDataServices();

    //----CONSTANTS
    const $q = new useQuasar();
    const store = new useStore();
    const router = new useRouter();
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;

    const companyServices = new CompanyServices();

    //----VARIABLES
    let auth = ref({
      username: "",
      password: "",
      loginInternal: true,
    });
    let isPwd = ref(true);
    let btnLoading = ref(false);
    let slide = ref(1);
    let remember = ref(false);
    let wordNamedBlockedArray = ref([]);

    function isValidEmail(val) {
      if (auth.value.username.indexOf("@") < 0) {
        return true;
      }
      return emailPattern.test(val) || "Email inválido";
    }

    async function onSubmit() {
      btnLoading.value = true;
      let data = {
        email: auth.value.username,
        password: auth.value.password,
        loginInternal: auth.value.loginInternal,
      };

      await authDataServices
        .authenticate(data)
        .then(async (response) => {
          if (response.data.token != null) {
            if (
              response.data.permissions == null ||
              response.data.menu == null
            ) {
              $q.notify({
                message: "O usuário não tem permissão para acessar o sistema.",
                color: "red-9",
                position: "top",
              });
              btnLoading.value = false;
              return;
            }
            localStorage.setItem("token", response.data.token);

            await saveLocalBadLanguages(response.data.data.company_id);

            store.commit("setUserData", response.data.data);
            store.commit("setPermissions", response.data.permissions);
            store.commit("setMenu", response.data.menu);
            store.commit("setBlacklist", wordNamedBlockedArray.value);
            router.push({ name: "layout" });
          }
        })
        .catch((error) => {
          btnLoading.value = false;

          let errorMenssageValidation =
            error.response.data
              .toString()
              .includes("Email não encontrado no sistema") ||
            error.response.data.toString().includes("Senha inválida");

          if (errorMenssageValidation) {
            $q.notify({
              message:
                "Email ou senha incorreta, por favor verifique os dados preenchidos",
              color: "red-9",
              position: "top",
            });
            return;
          }
          $q.notify({
            message: error.response.data.toString(),
            color: "red-9",
            position: "top",
          });
        });
    }

    async function saveLocalBadLanguages(id) {
      await companyServices
        .getAllbadlanguages(id)
        .then((response) => {
          response.data.forEach((element) => {
            wordNamedBlockedArray.value.push(element.description);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      //-----VARIABLES
      auth,
      isPwd,
      btnLoading,
      slide,
      remember,

      //----FUNCRIONS
      isValidEmail,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-login {
  width: 100%;
  height: 100vh;
  background-color: #1a1a1a;

  .content-painel-login {
    .painel-login {
      background-color: #fdfdfd;
      width: 95%;
      height: 95%;
      border-radius: 20px;

      .painel-form-login {
        height: 85%;
        width: 85%;
        padding: 5%;

        .brand-logo {
        }

        .header-form {
          font-style: normal;

          .title-header label {
            font-weight: 600;
            font-size: 22px;
            line-height: 111%;
            color: #000000;
          }

          .subtitle-header label {
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            color: #000000;
            opacity: 0.45;
          }
        }

        .action-form {
          height: 40%;

          .login-remember {
            .label-remember {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 17px;
              color: #000000;
              opacity: 0.45;
            }
          }

          .btn-login {
            width: 40%;
          }
        }

        .content-label-register {
          height: 10vh;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.3);

          .label-forget-my-password {
            a {
              text-decoration: none;
              color: rgba(0, 0, 0, 0.3);
              line-height: 33px;
            }
          }

          .label-register-with-link {
            a {
              text-decoration: none;
              color: #fe5268;
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .container-carousel {
    background-image: url("../assets/images/bg-shadow-login.png");

    .title-banner {
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 55px;
      text-align: center;
      color: #ffffff;
    }

    .panel-carousel {
      width: 75%;
    }
  }

  @media screen and (max-width: 1023px) {
    .container-carousel {
      visibility: hidden;
    }

    .content-painel-login {
      height: 100% !important;
    }

    .btn-login {
      width: 80%;
    }

    .section-btn {
      width: 100%;
    }
  }
}
</style>
